import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductDetailsComponent = _resolveComponent("ProductDetailsComponent")

  return (_openBlock(), _createBlock(_component_ProductDetailsComponent, {
    editMode: true,
    id: _ctx.id,
    tab: _ctx.tab
  }, null, 8, ["id", "tab"]))
}